import GtrStorage from '@/modules/common/services/storage.service'
import Container from 'typedi'
import { Component, Vue } from 'vue-property-decorator'
import GrtSecurityLayout from '../../layouts/security/security.layout.vue'

@Component({
  name: 'GtrCheckEmailView'
})
export default class GtrCheckEmailView extends Vue {
  data () {
    return {
      email: null
    }
  }

  updated () {
    this.$emit('update:layout', GrtSecurityLayout)
  }

  mounted () {
    const signupEmail = Container.get(GtrStorage).getItem('signedup_email')
    const resetEmail = Container.get(GtrStorage).getItem('reset_email')
    if (signupEmail !== null) {
      this.$data.email = signupEmail
    } else if (resetEmail !== null) {
      this.$data.email = resetEmail
      Container.get(GtrStorage).removeItem('reset_email')
    } else {
      this.$router.push({ name: 'security.login' })
    }
  }
}
